import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiCallingService } from "../../configs/api-calling.service";
import { NotifierService } from "angular-notifier";
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.css']
})

export class UpdateProfileComponent implements OnInit {
  private readonly notifier: NotifierService;
  public title = 'Update Profile';
  public submitted = false;
  fileToUpload: File = null;
  public fileUrl: string = '';
  userDetails: any = {};
  myForm: FormGroup;

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    public api: ApiCallingService,
    notifierService: NotifierService
  ) {
    this.notifier = notifierService
  }
  ngOnInit() {

    this.userDetails = JSON.parse(localStorage.getItem('userDetails'))
    if (this.userDetails.fileUrl) { this.fileUrl = environment.IMG_BASE_URL + '/' + this.userDetails.profilePic; }
    this.myForm = this.formBuilder.group({
      password: [''],
      new_password: [''],
      avatar: [''],
      email: [this.userDetails.email, [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]],
      name: [this.userDetails.name, [Validators.required]],
    })

  }

  submit() {
    let passValid = true;
    this.submitted = true;
    if (this.myForm.valid) {
      let api_url = 'Admin/update_profile';
      var formData = new FormData();
      formData.append('email', this.myForm.value.email)
      formData.append('name', this.myForm.value.name)
      formData.append('profilePic', this.myForm.value.avatar == null ? '' : this.myForm.value.avatar)
      formData.append('currentPassword', this.myForm.controls.password.value)
      formData.append('password', this.myForm.controls.new_password.value ? this.myForm.controls.new_password.value : '')
      if (this.myForm.value.password) {
        if (this.myForm.value.new_password) {

        } else {
          passValid = false
          alert('Please enter new password.')

        }
      }
      if (this.myForm.value.new_password) {
        if (this.myForm.value.password) {

        } else {
          passValid = false
          alert('Please enter current password.')
        }
      }
      if (passValid) {
        this.api.postRequest(api_url, formData).then(
          (res) => {
            this.submitted = false;
            console.log(res.result," this is main my response")
            // this.myForm.reset();
            if (res.code == 200) {

              this.api.setUserLoggedIn(res.result.accessToken, res.result)
              this.fileUrl = environment.IMG_BASE_URL + '/' + res.result.profilePic;
              this.notifier.notify('success', `Success: Profile updated successfully`)
            } else {
              this.notifier.notify('error', res.message)
            }
          },
          (err) => {
          }
        );
      } else {
        console.log("Enter valid details.")
      }

    }
  }

  showPreview(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.myForm.patchValue({
      avatar: file
    });
    this.myForm.get('avatar').updateValueAndValidity()

    // File Preview
    const reader = new FileReader();
    if (file.type.indexOf('image') > -1) {
      // this.format = 'image';
    } else if (file.type.indexOf('video') > -1) {
      // this.format = 'video';
      this.myForm.patchValue({
        isvideo: true
      });
      this.myForm.get('isvideo').updateValueAndValidity()

    }
    reader.onload = () => {
      this.fileUrl = reader.result as string;
    }
    reader.readAsDataURL(file)
  }

  resetForm() {
    this.submitted = false;
    this.myForm.reset();
    this.myForm.controls.avatar.setValue(null)
    this.fileUrl = '';
    this.myForm.controls.fluentID.setValue('');
  }
}
