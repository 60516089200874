import { Component, ViewChild, EventEmitter, Output, OnInit, AfterViewInit, Input } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiCallingService } from "../../../configs/api-calling.service";
import Swal from 'sweetalert2';
import { NotifierService } from "angular-notifier";
import { GooglePlaceDirective } from "../../../../../node_modules/ngx-google-places-autocomplete/ngx-google-places-autocomplete.directive";
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { NgxSpinnerService } from "ngx-spinner";
declare var $: any;
@Component({
  selector: 'app-add-work-profile',
  templateUrl: './add-work-profile.component.html',
  styleUrls: ['./add-work-profile.component.css']
})
export class AddWorkProfileComponent implements OnInit {
  public title = 'Add/Edit Work Profile'
  public responseData: any = [];
  myForm: FormGroup;
  submitted: Boolean = false;
  public id: string = ''
  public agencyList: any = [];
  public options: any = [];
  public typeOfCare: any = [];
  public typeOfCareValues: any = [];
  public certAndExp: any = [];
  public certAndExpValues: any = [];
  public experienceQuali: any = [];
  public experienceQualiValues: any = [];
  public experienceAge: any = [];
  public experienceAgeValues: any = [];
  public avalibility: any = [];
  public avalibilityValues: any = [];
  public disablitiesAndCare: any = [];
  public disablitiesAndCareValues: any = [];


  public search = '';
  // pagination var
  itemsPerPage: number = 10;
  totalRecords: number;
  skip: number = 0;
  p: number = 1;

  private readonly notifier: NotifierService;
  @ViewChild("placesRef") placesRef: GooglePlaceDirective;
  address: any = {
    address_name: '',
    lattitude: '',
    longitude: '',
    zipcode: ''
  };
  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private router: Router,
    public api: ApiCallingService,
    notifierService: NotifierService,
    public activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,

  ) {
    this.notifier = notifierService;
  }

  ngOnInit() {
    this.spinner.show()
    this.activatedRoute.params.subscribe(params => {
      this.id = params['id'];

    })
    this.myForm = this.formBuilder.group({
      agency: ["", [Validators.required]],
      pets_comfortable: [false, [Validators.required]],
      non_smoker: [false, [Validators.required]],
      eng_spn: [false, [Validators.required]],
      type_of_care: [""],
      experience: ["", [Validators.required]],
      work_address_name: [""],
      experience_age_groups: [""],
      exp_cert: [""],
      bio: [""],
      age: ["", [Validators.required, Validators.pattern("^[0-9]*$"), Validators.maxLength(2)]],
      gender: ["1", [Validators.required]],
      disablities_care: [""],
      // setAvalibility: ["", [Validators.required]],
      transportation: [false, [Validators.required]],
      hours_per_week: ["", [Validators.required, Validators.pattern("^[0-9]*$")]],
      miles: ["", [Validators.required, Validators.pattern("^[0-9]*$")]],
      zipcode: ["", [Validators.required]],
    });
    this.getData()
  }

  AfterViewInit() {
    this.spinner.hide()
  }
  getData() {
    this.spinner.show()
    // stop here if form is invalid 
    if (this.search) {
      this.p = 1;
      this.skip = this.itemsPerPage * this.p - this.itemsPerPage;
    }
    let api_url = 'Admin/agencyList';

    this.api.getRequest(api_url, {}).then(
      (res) => {
        if (res != undefined && (res.code == 200 || res.code == 202)) {
          this.totalRecords = res.totalCount;
          this.agencyList = res.result.agencyList;
          this.typeOfCare = res.result.typeOfCare;
          this.certAndExp = res.result.certAndExp;
          this.experienceQuali = res.result.experienceQuali;
          this.avalibility = res.result.avalibility;
          this.disablitiesAndCare = res.result.disablitiesAndCare;
          this.experienceAge = res.result.experienceWithAge
          this.myForm.controls.agency.setValue('');

          let api_url = 'Admin/user_detail?';
          api_url = api_url + '_id=' + this.id;

          this.api.getRequest(api_url, { _id: this.id }).then(
            (res) => {
              this.spinner.hide();
              if (res != undefined && res.code == 200) {
                this.responseData = res.data[0]
                let response = res.data[0]
                this.myForm.patchValue({
                  agency: response.agency_name && response.agency_name != undefined ? response.agency_name : '',
                  bio: response.bio,
                  age: response.age,
                  gender: response.gender,
                  work_address_name: response.work_address_name,
                  pets_comfortable: response.pets_comfortable,
                  non_smoker: response.non_smoker,
                  transportation: response.transportation,
                  hours_per_week: response.hours_per_week,
                  experience: response.experience,
                  miles: response.miles != undefined ? response.miles : '',
                  // setAvalibility: response.availability && response.availability != undefined ? response.availability : '',
                  zipcode: response.zipcode && response.zipcode != undefined ? response.zipcode : '',
                })
                this.experienceQualiValues = response.experience;
                this.experienceAgeValues = response.experience_age_groups;
                this.typeOfCareValues = response.type_and_care;
                this.avalibilityValues = Array.isArray(response.availability) ? response.availability : [response.availability];
                this.disablitiesAndCareValues = response.disablities_care;
                this.certAndExpValues = response.exp_cert;
                this.address = {
                  address_name: response.work_address_name,
                  lattitude: response.work_address.coordinates[1],
                  longitude: response.work_address.coordinates[0]
                }
              } else {

              }
            },
            (err) => {

            }
          );
        } else {

        }
      },
      (err) => {

      }
    );

  }

  addValues(type, id) {
    if (type == 1) {
      if (this.typeOfCareValues.includes(id)) {
        this.typeOfCareValues = this.typeOfCareValues.slice(0, this.typeOfCareValues.indexOf(id));
      } else {
        this.typeOfCareValues.push(id)
      }
    } else if (type == 2) {
      if (this.certAndExpValues.includes(id)) {
        this.certAndExpValues = this.certAndExpValues.slice(0, this.certAndExpValues.indexOf(id));
      } else {
        this.certAndExpValues.push(id)
      }
    }
    else if (type == 3) {
      if (this.disablitiesAndCareValues.includes(id)) {
        this.disablitiesAndCareValues = this.disablitiesAndCareValues.slice(0, this.disablitiesAndCareValues.indexOf(id));
      } else {
        this.disablitiesAndCareValues.push(id)
      }
    }
    else if (type == 4) {

      if (this.experienceQualiValues.includes(id)) {
        this.experienceQualiValues = this.experienceQualiValues.slice(0, this.experienceQualiValues.indexOf(id));
      } else {
        this.experienceQualiValues.push(id)
      }
    }
    else if (type == 5) {
      if (this.avalibilityValues.includes(id)) {
        this.avalibilityValues = this.avalibilityValues.slice(0, this.avalibilityValues.indexOf(id));
      } else {
        this.avalibilityValues.push(id)
      }
    }
    else if (type == 6) {
      if (this.experienceAgeValues.includes(id)) {
        this.experienceAgeValues = this.experienceAgeValues.slice(0, this.experienceAgeValues.indexOf(id));
      } else {
        this.experienceAgeValues.push(id)
      }
    }

  }
  ngAfterViewInit() {
  }

  public handleAddressChange(address: Address) {
    // Do some stuff
    this.address = {
      address_name: address.formatted_address,
      lattitude: address.geometry.location.lat(),
      longitude: address.geometry.location.lng()
    }
    let components = address.address_components;
    for (var i in components) {
      console.log(components[i].types[0] == 'postal_code', components[i].short_name)
      if (components[i].types[0] == 'postal_code') {
        this.myForm.patchValue({
          zipcode: components[i].short_name
        })
      }
    }

  }

  submit() {

    this.submitted = true;

    let requestData = {
      "user_id": this.id,
      "agency_name": this.myForm.controls.agency.value,
      "work_latitude": this.address.lattitude,
      "work_longitude": this.address.longitude,
      "work_address_name": this.address.address_name,
      "type_and_care": this.typeOfCareValues.toString(),
      "pets_comfortable": this.myForm.controls.pets_comfortable.value,
      "non_smoker": this.myForm.controls.non_smoker.value,
      "transportation": this.myForm.controls.transportation.value,
      "exp_cert": this.certAndExpValues.toString(),
      "disablities_care": this.disablitiesAndCareValues.toString(),
      "availability": this.avalibilityValues.toString(),
      "hours_per_week": this.myForm.controls.hours_per_week.value,
      "experience": this.myForm.controls.experience.value,
      "experience_age_groups": this.experienceAgeValues.toString(),
      "eng_spn": this.myForm.controls.eng_spn.value,
      "bio": this.myForm.controls.bio.value,
      "age": this.myForm.controls.age.value,
      "gender": this.myForm.controls.gender.value,
      "zipcode": this.myForm.controls.zipcode.value,
      "miles": this.myForm.controls.miles.value
    }
    console.log("ggggggggg", this.myForm)
    if (this.myForm.invalid || this.typeOfCareValues.length == 0 || this.disablitiesAndCare.length == 0 || this.avalibilityValues.length == 0) {
      return;
    } else {
      let api_url = 'Admin/add_work_profile'
      this.api.postRequest(api_url, requestData).then(
        (res) => {
          this.submitted = false;
          if (res.code == 200) {
            this.myForm.reset();
            this.notifier.notify('success', `Success: ${res.message}`);
            this.router.navigate(['/main/user_management']);
          } else {
            this.notifier.notify('error', `Error: ${res.message}`);
          }
        },
        (err) => {

        }
      );
    }
  }

}
