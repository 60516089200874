import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiCallingService } from "../../../configs/api-calling.service";
import { NotifierService } from "angular-notifier";

@Component({
  selector: 'app-edit-admin',
  templateUrl: './edit-admin.component.html',
  styleUrls: ['./edit-admin.component.css']
})
export class EditAdminComponent implements OnInit {
  public title = 'Edit Admin';
  public myForm: FormGroup;
  public submitted: Boolean = false;
  public tabsData: any = [];
  public responseData: any = [];
  public id : string ='';
  private readonly notifier: NotifierService;
  public checkedArray: any = [];
  public role: string = '';
  public navigate: string = '';
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private router: Router,
    public api: ApiCallingService,
    notifierService: NotifierService,
    private activatedRoute: ActivatedRoute

  ) { this.notifier = notifierService }

   ngOnInit() {
    this.myForm = this.formBuilder.group({

      name: ['', [Validators.required, this.api.noWhitespace]],
      email: ['', [Validators.required, Validators.compose([Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')])]],
      mobileNumber: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(15), this.api.noWhitespace]],
      avatar: [null]
      
    });
    if (window.location.hash == '#/main/user_management/add_employee') {
      this.role = 'EMPLOYER'
      this.title = 'Add Employer'
      this.navigate= '/main/user_management';
    } else if (window.location.hash == '#/main/guardian_management/add_guardian') {
      this.role = 'FAMILY'
      this.title = 'Add Guardian'
      this.navigate= '/main/guardian_management';
    } else {
      this.title = 'Add Agency'
      this.role = 'ADMIN';
      this.navigate = '/main/agency_management';
    }
     this.activatedRoute.params.subscribe(params => {
      this.id = params['id'];
      this.getData()
    })
  }


  

 
  getData() {
    // stop here if form is invalid 

    let api_url = 'Admin/user_detail?';
    api_url = api_url + '_id=' + this.id;

    this.api.getRequest(api_url, { _id: this.id }).then(
      (res) => {
        if (res != undefined && res.code == 200) {
      
	        this.responseData = res.data[0] ; 
            this.checkedArray= this.responseData.permission;
            this.myForm = this.formBuilder.group({
			      name: [this.responseData.name, [Validators.required,this.api.noWhitespace]],
			      email: [this.responseData.email, [Validators.required, Validators.compose([Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$') ])]],
			      mobileNumber: [this.responseData.mobileNumber, [Validators.required, Validators.minLength(4) , Validators.maxLength(15),this.api.noWhitespace]],
			      countryCode: [this.responseData.countryCode, [Validators.required, this.api.noWhitespace]],
			      avatar: [null]
		    });

        } else {
          if (res) {
         
            this.notifier.notify('error', `Error: ${res.message}`);
          }
        }
      },
      (err) => {

      }
    );

  }

  addPermission(event, id) {
    if (this.checkedArray.includes(id)) {
      
      this.checkedArray = this.checkedArray.slice(0, this.checkedArray.indexOf(id))
    } else {
   
      this.checkedArray.push(id)

    }
  }
 
  submit() { 
   
    this.submitted = true;
    let api_url = 'Admin/editAdmin'

    var formData = new FormData();
    formData.append('name', this.myForm.value.name)
    formData.append('email', this.myForm.value.email)
    formData.append('mobileNumber', this.myForm.value.mobileNumber)
    formData.append('role', this.role)
    
      
    if (this.myForm.valid) {  
      this.api.postRequest(api_url, formData).then(
        (res) => {
           
           if (res.code == 200) {

             this.notifier.notify('success', `Success: ${res.message}`);
             //this.resetForm();
             this.router.navigate(['/admin/admin_management'])
           } else {
             this.notifier.notify('error', `Error: ${res.message}`);
           }
        },
        (err) => {
        }
      );
    }
  }
   resetForm(){
    this.myForm.reset();
    this.checkedArray=[];
  }
 }

