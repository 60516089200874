import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiCallingService } from "../../../configs/api-calling.service";
import { NotifierService } from "angular-notifier";
import { NgxSpinnerService } from "ngx-spinner";

declare var $: any;

@Component({
  selector: 'app-request-view',
  templateUrl: './request-view.component.html',
  styleUrls: ['./request-view.component.css']
})
export class RequestViewComponent implements OnInit {
  public title = 'Request Detail';
  public responseData: any = [];
  public id: string = '';
  public IMAGE_BASE_URL;
  public agencyList: any = [];
  public typeOfCare: any = [];
  public typeOfCareValues: any = [];
  public certAndExp: any = [];
  public certAndExpValues: any = [];
  public experienceQuali: any = [];
  public experienceQualiValues: any = [];
  public experienceAge: any = [];
  public experienceAgeValues: any = [];
  public avalibility: any = [];
  public avalibilityValues: any = [];
  public disablitiesAndCare: any = [];
  public disablitiesAndCareValues: any = [];
  public request_id: string = '';
  public selectedEmployees: any = [];
  public usersData: any = [];
  private readonly notifier: NotifierService;

  submitted: Boolean = false;

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private router: Router,
    public api: ApiCallingService,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    notifierService: NotifierService
  ) { this.notifier = notifierService; }

  ngOnInit() {
    this.spinner.show()
    this.activatedRoute.params.subscribe(params => {
      this.id = params['id'];
      this.getData()
    })
    this.IMAGE_BASE_URL = this.api.IMAGE_BASE_URL;
  }

  ngAfterViewInit() {
    this.spinner.hide()
  }
  submit() {
    this.submitted = true;
    if (this.selectedEmployees.length > 0) {
      this.spinner.show()
      let api_url = 'Admin/assignEmployee'
      this.responseData = {
        request_id: this.request_id,
        userIds: this.selectedEmployees.toString()
      }
      this.api.postRequest(api_url, this.responseData).then(

        (res) => {
          this.submitted = false;
          this.spinner.hide()

          if (res && res.code == 200) {
            $('.closeId').click();
            this.getData();
            // this.router.navigate(['/main/request_management']);
            this.notifier.notify('success', `Success: ${res.message}`);
          } else {
            this.notifier.notify('error', `Error: ${res.message}`);
          }
        },
        (err) => {

        }
      );
    } else {

    }


  }

  addValues(type, id) {
    if (type == 1) {
      if (this.typeOfCareValues.includes(id)) {
        this.typeOfCareValues = this.typeOfCareValues.slice(0, this.typeOfCareValues.indexOf(id));
      } else {
        this.typeOfCareValues.push(id)
      }
    } else if (type == 2) {
      if (this.certAndExpValues.includes(id)) {
        this.certAndExpValues = this.certAndExpValues.slice(0, this.certAndExpValues.indexOf(id));
      } else {
        this.certAndExpValues.push(id)
      }
    }
    else if (type == 3) {
      if (this.disablitiesAndCareValues.includes(id)) {
        this.disablitiesAndCareValues = this.disablitiesAndCareValues.slice(0, this.disablitiesAndCareValues.indexOf(id));
      } else {
        this.disablitiesAndCareValues.push(id)
      }
    }
    else if (type == 4) {

      if (this.experienceQualiValues.includes(id)) {
        this.experienceQualiValues = this.experienceQualiValues.slice(0, this.experienceQualiValues.indexOf(id));
      } else {
        this.experienceQualiValues.push(id)
      }
    }
    else if (type == 5) {
      if (this.avalibilityValues.includes(id)) {
        this.avalibilityValues = this.avalibilityValues.slice(0, this.avalibilityValues.indexOf(id));
      } else {
        this.avalibilityValues.push(id)
      }
    }
    else if (type == 6) {
      if (this.experienceAgeValues.includes(id)) {
        this.experienceAgeValues = this.experienceAgeValues.slice(0, this.experienceAgeValues.indexOf(id));
      } else {
        this.experienceAgeValues.push(id)
      }
    }

  }
  addValues1(id) {
    if (this.selectedEmployees.includes(id)) {
      this.selectedEmployees = this.selectedEmployees.slice(0, this.selectedEmployees.indexOf(id));
    } else {
      this.selectedEmployees.push(id)
    }
  }
  getData() {

    // stop here if form is invalid 

    let api_url = 'Admin/request_detail?';
    api_url = api_url + '_id=' + this.id;

    this.api.getRequest(api_url, { _id: this.id }).then(
      (res) => {

        if (res != undefined && res.code == 200) {
          this.responseData = res.data[0];

          let api_url = 'Admin/agencyList';

          this.api.getRequest(api_url, {}).then(
            (res) => {
              if (res != undefined && (res.code == 200 || res.code == 202)) {

                this.agencyList = res.result.agencyList;
                this.typeOfCare = res.result.typeOfCare;
                this.typeOfCareValues = this.responseData.type_and_care
                this.certAndExp = res.result.certAndExp;
                this.certAndExpValues = this.responseData.exp_cert;
                this.experienceQuali = res.result.experienceQuali;
                this.experienceQualiValues = this.responseData.exp_cert;
                this.avalibility = res.result.avalibility;
                this.avalibilityValues = this.responseData.availability;
                this.disablitiesAndCare = res.result.disablitiesAndCare;
                this.disablitiesAndCareValues = this.responseData.disablities_care
                this.experienceAge = res.result.experienceWithAge;

                let api_url = 'Admin/user_management?skip=' + 0 + '&limit=' + 10000000 + '&search=""&type=EMPLOYER';
                this.api.getRequest(api_url, {}).then(
                  (res) => {
                    if (res != undefined && (res.code == 200 || res.code == 202)) {
                      this.usersData = res.data
                    } else {
                      if (res) {
                        alert(res.message)

                      }
                    }
                  },
                  (err) => {

                  }
                );
              } else {
                if (res) {
                  alert(res.message)
                }
              }
            },
            (err) => {

            }
          );

        }
      })
  }

  setId(id, userIds) {
    this.request_id = id;
    this.selectedEmployees = userIds;
  }
}
