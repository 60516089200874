import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiCallingService } from "../../configs/api-calling.service";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reported-posts',
  templateUrl: './reported-posts.component.html',
  styleUrls: ['./reported-posts.component.css']
})
export class ReportedPostsComponent implements OnInit {
  public title = 'Reported Lessons';
  public responseData: any = [];
  public fileUrl: any = '';
  public search = '';
  // pagination var
  itemsPerPage: number = 10;
  totalRecords: number;
  skip: number = 0;
  p: number = 1;
  constructor(
    private http: HttpClient,
    private router: Router,
    public api: ApiCallingService
  ) { }

  ngOnInit() {

    this.getData();

  }

  getData() {

    // stop here if form is invalid 

    let api_url = 'Admin/reported_posts?type=LESSON';
    if (window.location.hash == '#/main/lesson_management/user_lesson') {
      api_url = api_url + '&adminCreated=false' + '&isDeleted=false'
    } else {
      api_url = api_url + '&adminCreated=true' + '&isDeleted=false'
    }
    if (this.search) {
      api_url = api_url + '&search=' + this.search + '&skip=' + this.skip + '&limit=' + this.itemsPerPage;
    }

    this.api.getRequest(api_url, {}).then(
      (res) => {
        if (res != undefined && (res.code == 200 || res.code == 202)) {
          this.responseData = res.data;
          this.totalRecords = res.totalCount;
          // this.search = ''
        } else {
          if (res) {
            // alert(res.message)
          }
        }
      },
      (err) => {

      }
    );
  }

  pageChanged(val) {

    this.skip = this.itemsPerPage * val - this.itemsPerPage;
    this.getData()

  }

  deleteFluent(Id) {
    Swal.fire({
      title: 'Are you sure, You want to delete this lesson ?',
      text: 'You will not be able to recover this lesson.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {

      if (result.value) {

        this.api.postRequest('Admin/deleteFluent', { _id: Id }).then(
          (res) => {
            if (res && (res.code == 200 || res.code == 202)) {
              Swal.fire(
                'Deleted!',
                'Lesson has been deleted.',
                'success'
              )
              for (var i in this.responseData) {
                if (this.responseData[i]._id.toString() == Id.toString()) {
                  this.responseData.splice(i, 1);
                }
              }

            } else {

              Swal.fire(
                'Something Went Wrong.',
                'Please try Again after some time.',
                'error'
              )
            }

          },
          (err) => {

            Swal.fire(
              'Something Went Wrong.',
              'Please try Again after some time.',
              'error'
            )
          }
        );

      } else if (result.dismiss === Swal.DismissReason.cancel) {

        Swal.fire(
          'Cancelled',
          'Your request has been cancelled',
          'error'
        )
      }
    })
  }

}
