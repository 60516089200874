import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiCallingService } from "../../configs/api-calling.service";
import Swal from 'sweetalert2';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { NotifierService } from "angular-notifier";

@Component({
  selector: 'app-content-management',
  templateUrl: './content-management.component.html',
  styleUrls: ['./content-management.component.css']
})
export class ContentManagementComponent implements OnInit {
  public Editor = ClassicEditor;
  public submitted: false;
  content: string;
  type: number;
  htmlContent = '';
  title = '';
  private readonly notifier: NotifierService;


  constructor(
    private router: Router, 
    public api: ApiCallingService,
    notifierService: NotifierService

    ) {
      this.notifier = notifierService;
    }
  ngOnInit() {
    if (window.location.hash == '#/cancellation-policy') {
      this.type = 5
      this.title = 'Cancellation Policy'
      this.getData()
    } else if (window.location.hash == '#/main/privacy_policy') {
      this.type = 2
      this.title = 'Privacy Policy'
      this.getData()
    } else if (window.location.hash == '#/main/about_us') {
      this.type = 3
      this.title = 'About us'
      this.getData()
    } else if (window.location.hash == '#/main/terms_and_condition') {
      this.type = 1
      this.title = 'Terms & Condition'
      this.getData()
    } else if (window.location.hash == '#/main/disclaimer') {
      this.type = 4
      this.title = 'Disclaimer'
      this.getData()
    }
    else if (window.location.hash == '#/support') {
      this.type = 6
      this.title = 'support'
      this.getData()
    }
    else {
      console.log("No type")
    }

  }

  getData() {

    this.api.getRequest('Admin/content_management?type=' + this.type, {}).then(
      (res) => {
        if (res && res.code == 200) {
          if (res.result != null) {
            this.htmlContent = res.result.content;
          }
        } else {

        }
      },
      (err) => {
      }
    );
  }

  submit() {

    let api_url = 'Admin/add_content'
    this.api.postRequest(api_url, {
      "content": this.htmlContent,
      "type": this.type
    }).then(
      (res) => {
        this.submitted = false;

        if (res.code == 200) {
          let message = this.title + ' updated successfully.'
          this.notifier.notify('success', `Success: ${message}.`);
        } else {
          this.notifier.notify('error', `Error: ${res.message}.`);
        }
      },
      (err) => {
      }
    );
  }
}

