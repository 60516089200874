import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './view/login/login.component';
import { ForgotPasswordComponent } from './view/forgot-password/forgot-password.component';
import { DashboardComponent } from './view/dashboard/dashboard.component';

//services
import { CanActivateRouteGuard } from "./configs/can-activate-route.guard"

// modules
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxPaginationModule} from 'ngx-pagination';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NotifierModule } from "angular-notifier";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { Ng2SearchPipeModule } from 'ng2-search-filter';

//services
import { Interceptor } from './configs/interceptor';
import { HeaderDirective } from './directives/header.directive';
import { HeaderComponent } from './view/header/header.component';
import { MainComponent } from './view/main/main.component';
import { FooterComponent } from './view/footer/footer.component';
import { SidebarComponent } from './view/sidebar/sidebar.component';
import { CategoryComponent } from './view/category/category.component';
import { EditCategoryComponent } from './view/edit-category/edit-category.component';
import { AddCategoryComponent } from './view/add-category/add-category.component';
import { AddSubcategoryComponent } from './view/add-subcategory/add-subcategory.component';
import { EditSubcategoryComponent } from './view/edit-subcategory/edit-subcategory.component';
import { SubcategoryComponent } from './view/subcategory/subcategory.component';
import { UserManagementComponent } from './view/user-management/user-management.component';
import { TestComponent } from './view/test/test.component';
import { UserDetailComponent } from './view/user-detail/user-detail.component';
import { ChangePasswordComponent } from './view/change-password/change-password.component';
import { ContentManagementComponent } from './view/content-management/content-management.component';
import { PagenotfoundComponent } from './view/pagenotfound/pagenotfound.component';
import { ReportedPostsComponent } from './view/reported-posts/reported-posts.component';
import { CaptalisePipe } from './configs/captalise.pipe';
import { AgencyManagementComponent } from './view/agency-management/agency-management.component';
import { AddAdminComponent } from './view/agency-management/add-admin/add-admin.component';
import { ViewAdminComponent } from './view/agency-management/view-admin/view-admin.component';
import { EditAdminComponent } from './view/agency-management/edit-admin/edit-admin.component';
import { GuradianManagementComponent } from './view/guradian-management/guradian-management.component';
import { AddWorkProfileComponent } from './view/user-management/add-work-profile/add-work-profile.component';
import { AddAgencyComponent } from './view/agency-management/add-agency/add-agency.component';
import { RequestManagementComponent } from './view/request-management/request-management.component';
import { RequestViewComponent } from './view/request-management/request-view/request-view.component';
import { ApproveAndNotifyComponent } from './view/request-management/approve-and-notify/approve-and-notify.component';
import { NotificationsComponent } from './view/notifications/notifications.component';
import { SubscriptionComponent } from './view/subscription/subscription.component';
import { UpdateProfileComponent } from './view/update-profile/update-profile.component';
import { CaregiverManagementComponent } from './view/caregiver-management/caregiver-management.component';
@NgModule({
  declarations: [
    
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    DashboardComponent,
    HeaderDirective,
    HeaderComponent,
    MainComponent,
    FooterComponent,
    SidebarComponent,
    CategoryComponent,
    EditCategoryComponent,
    AddCategoryComponent,
    AddSubcategoryComponent,
    SubcategoryComponent,
    EditSubcategoryComponent,
    UserManagementComponent,
    TestComponent,
    UserDetailComponent,
    ChangePasswordComponent,
    ContentManagementComponent,
    PagenotfoundComponent,
    ReportedPostsComponent,
    CaptalisePipe,
    AgencyManagementComponent,
    GuradianManagementComponent,
    AddAdminComponent,
    EditAdminComponent,
    ViewAdminComponent,
    AddWorkProfileComponent,
    AddAgencyComponent,
    RequestManagementComponent,
    RequestViewComponent,
    ApproveAndNotifyComponent,
    NotificationsComponent,
    SubscriptionComponent,
    UpdateProfileComponent,
    CaregiverManagementComponent
    // ApiCallingService
  ],
  exports: [
    HeaderDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    NgxPaginationModule,
    CKEditorModule,
    NotifierModule,
    GooglePlaceModule,
    Ng2SearchPipeModule,
    
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true
    },
    CanActivateRouteGuard
  ]
})
export class AppModule { }
