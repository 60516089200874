import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiCallingService } from "../../../app/configs/api-calling.service";
import { NotifierService } from "angular-notifier";
declare var $: any;
@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.css']
})
export class AddCategoryComponent implements OnInit {
  public CAT_TYPES = [
    'TYPE_OF_CARE',
    'CERT_AND_EXP',
    'DISABILITIES_AND_CARE',
    'EXPERIENCE_QUALI',
    'EXPERIENCE_WITH_AGE',
    'AVAILABILITY'
  ];
 
  public category_name: string = '';
  public category_type: string = '';
  public submitted = false;
  public responseData: any = [];
  fileToUpload: File = null;
  public fileUrl: any = '';
  private readonly notifier: NotifierService;

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private router: Router,
    public api: ApiCallingService,
    notifierService: NotifierService

  ) {
    this.notifier = notifierService;
  }

  ngOnInit() {
  }

  addCategory() {
    this.submitted = true;
    if (this.category_name != '' && this.category_type != '') {
      const apiUrl = 'Admin/add_category';

      const formData = new FormData();
      formData.append('category_name', this.category_name)
      formData.append('file', this.fileToUpload == null ? '' : this.fileToUpload)
      formData.append('type', this.category_type);

      // if (this.fileToUpload) {

      this.api.postRequest(apiUrl, formData).then(
        (res) => {
          this.submitted = false;
          this.fileToUpload = null;
          this.category_name = '';
          if (res.code === 200) {
            this.notifier.notify('success', `Success: ${res.message}`);
            this.router.navigate(['/main/category_management']);
          } else {
            this.notifier.notify('error', `Error: ${res.message}`);
          }
        },
        (err) => {

        }
      );
      // }
    }
  }

  handleFileInput(files: FileList) {
    var mimeType = files.item(0).type;

    var reader = new FileReader();

    reader.readAsDataURL(files.item(0));
    reader.onload = (_event) => {
      this.fileUrl = reader.result;
    }

    this.fileToUpload = files.item(0);
  }
  resetForm() {
    this.submitted = false;
    this.fileToUpload == null;
    this.fileUrl = '';
    this.category_name = '';
    this.category_type = '';
  }
}
