import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiCallingService } from "../../configs/api-calling.service";
import { NotifierService } from "angular-notifier";

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  public title = 'Change Password';
  public submitted = false;
  myForm: FormGroup;
  private readonly notifier: NotifierService;

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    public api: ApiCallingService,
    notifierService: NotifierService

    ) {
      this.notifier = notifierService;
    }
  ngOnInit() {
    this.myForm = this.formBuilder.group({
      password: ['', [Validators.required]],
      new_password: ['', [Validators.required]],

    })
  }

  submit() {
    this.submitted = true;
    if (this.myForm.valid) {
      let api_url = 'Admin/change_password';

      this.api.postRequest(api_url, {
        currentPassword: this.myForm.controls.password.value,
        password: this.myForm.controls.new_password.value ? this.myForm.controls.new_password.value : ''
      }).then(
        (res) => {
          this.submitted = false;
          this.myForm.reset();
          if (res.code == 200) {
            this.notifier.notify('success', `Success: Password changed successfully.`);
          } else {
            this.notifier.notify('error', `Error: ${res.message}.`);
          }
        },
        (err) => {
        }
      );
    }
  }
}
