import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiCallingService } from "../../configs/api-calling.service";
import Swal from 'sweetalert2';
import { NotifierService } from "angular-notifier";

declare var $: any;

@Component({
  selector: 'app-request-management',
  templateUrl: './request-management.component.html',
  styleUrls: ['./request-management.component.css']
})
export class RequestManagementComponent implements OnInit {
  public title = 'Manage Requests';
  public responseData: any = [];
  public search = '';
  public request_id: string = ''
  // pagination var
  itemsPerPage: number = 10;
  usersData: any = [];
  selectedEmployees: any = [];
  totalRecords: number;
  skip: number = 0;
  p: number = 1;
  submitted: Boolean = false;
  private readonly notifier: NotifierService;

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private router: Router,
    public api: ApiCallingService,
    notifierService: NotifierService

  ) {
    this.notifier = notifierService;
  }

  ngOnInit() {
    this.getData();

  }


  getData() {

    // stop here if form is invalid 
    if (this.search) {
      this.p = 1;
      this.skip = this.itemsPerPage * this.p - this.itemsPerPage;
    }
    let api_url = 'Admin/request_management?skip=' + this.skip + '&limit=' + this.itemsPerPage + '&search=' + this.search

    this.api.getRequest(api_url, {}).then(
      (res) => {
        if (res != undefined && (res.code == 200 || res.code == 202)) {
          this.totalRecords = res.totalCount;
          this.responseData = res.data;
          let api_url = 'Admin/user_management?skip=' + 0 + '&limit=' + 10000000 + '&search=' + this.search + '&type=EMPLOYER';
          this.api.getRequest(api_url, {}).then(
            (res) => {
              if (res != undefined && (res.code == 200 || res.code == 202)) {
                this.usersData = res.data
              } else {
                if (res) {
                  alert(res.message)

                }
              }
            },
            (err) => {

            }
          );
        } else {
          if (res) {
            alert(res.message)

          }
        }
      },
      (err) => {

      }
    );

  }

  addValues(id) {
    if (this.selectedEmployees.includes(id)) {
      this.selectedEmployees = this.selectedEmployees.slice(0, this.selectedEmployees.indexOf(id));
    } else {
      this.selectedEmployees.push(id)
    }
  }
  setId(id, userIds) {
    this.request_id = id;
    this.selectedEmployees = userIds;
  }
  submit() {

    this.submitted = true;
    if (this.selectedEmployees.length > 0) {

      let api_url = 'Admin/assignEmployee'
      this.responseData = {
        request_id: this.request_id,
        userIds: this.selectedEmployees.toString()
      }
      this.api.postRequest(api_url, this.responseData).then(
        (res) => {
          this.submitted = false;
          if (res && res.code == 200) {
            $('.closeId').click();
            this.getData();
            this.router.navigate(['/main/request_management']);
            this.notifier.notify('success', `Success: ${res.message}`);
          } else {
            this.notifier.notify('error', `Error: ${res.message}`);
          }
        },
        (err) => {

        }
      );
    } else {

    }


  }

  activeInactive(val, status) {

    Swal.fire({
      text: status ? 'Are you sure, You want to reject this user ?' : 'Are you sure, You want to approve this user?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Submit',
      cancelButtonText: 'Cancel'
    }).then((result) => {

      if (result.value) {

        this.api.postRequest('Admin/blockUnBlockUser', {
          user_id: val,
          block: status ? true : false
        }).then(
          (res) => {

            if (res && res.code == 200) {
              Swal.fire(
                'Success!',
                status ? 'User marked inactive successfully.' : 'User marked active successfully.',
                'success'
              )

              for (var i in this.responseData) {
                if (this.responseData[i]._id.toString() == val.toString()) {
                  this.responseData[i].isBlocked = status;
                }
              }

            } else {
              Swal.fire(
                'Something Went Wrong.',
                'Please try again after some time.',
                'error'
              )
            }
          },
          (err) => {
            Swal.fire(
              'Something Went Wrong.',
              'Please try again after some time.',
              'error'
            )
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your request has been cancelled',
          'error'
        )
      }
    })
  }

  pageChanged(val) {

    this.skip = this.itemsPerPage * val - this.itemsPerPage;
    this.getData()

  }

  deleteUser(userId) {
    Swal.fire({
      title: 'Are you sure, You want to delete this user ?',
      text: 'You will not be able to recover this user.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {

      if (result.value) {

        this.api.postRequest('Admin/deleteUser', { user_id: userId }).then(
          (res) => {
            if (res && res.code == 200) {
              Swal.fire(
                'Deleted!',
                'User has been deleted.',
                'success'
              )
              for (var i in this.responseData) {
                if (this.responseData[i]._id.toString() == userId.toString()) {
                  this.responseData.splice(i, 1);
                }
              }

            } else {

              Swal.fire(
                'Something Went Wrong.',
                'Please try Again after some time.',
                'error'
              )
            }

          },
          (err) => {

            Swal.fire(
              'Something Went Wrong.',
              'Please try Again after some time.',
              'error'
            )
          }
        );

      } else if (result.dismiss === Swal.DismissReason.cancel) {

        Swal.fire(
          'Cancelled',
          'Your request has been cancelled',
          'error'
        )
      }
    })
  }

}
