import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiCallingService } from "../../configs/api-calling.service";
import { environment } from 'src/environments/environment';
import { NotifierService } from "angular-notifier";

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.css']
})
export class UserDetailComponent implements OnInit {

  public responseData: any = [];
  public IMAGE_BASE_URL : string = '';
  private readonly notifier: NotifierService;
  public imagePath : string = '';
  public title = 'User Detail';
  public id: string = '';
  public file : any = null;
  public fileUrl: string = '';
  public submitted : boolean  = false;
  public setRouting = '/main/user_management'
  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private router: Router,
    public api: ApiCallingService,
    private activatedRoute: ActivatedRoute,
    private detector : ChangeDetectorRef,
    notifierService: NotifierService
    ) {
      this.notifier = notifierService
    }
  updateFormGroup = new FormGroup({
    name : new FormControl('',Validators.required),
    email : new FormControl('',Validators.required),
    mobileNumber : new FormControl('',Validators.required)
  });

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.id = params['id'];
      this.getData()
    })
    this.IMAGE_BASE_URL = this.api.IMAGE_BASE_URL;
    // request_management/family_detail/:id
    if (window.location.hash == '#/main/request_management/family_detail/' + this.id) {
      this.setRouting = '/main/request_management'
      this.title = 'Family Detail'
    }
    else if (window.location.hash == '#/main/guardian_management/user_detail/' + this.id) {
      this.setRouting = '/main/guardian_management'
      this.title = 'Guardian Detail'
    }
    else if (window.location.hash == '#/main/user_management/user_detail/' + this.id) {
      this.setRouting = '/main/user_management'
      this.title = 'User Detail'
    }
    else if (window.location.hash == '#/main/agency_management/user_detail/' + this.id) {
      this.setRouting = '/main/agency_management'
      this.title = 'Agency Detail'
    }
  }

  getData() {

    // stop here if form is invalid 

    let api_url = 'Admin/user_detail?';
    api_url = api_url + '_id=' + this.id;

    this.api.getRequest(api_url, { _id: this.id }).then(
      (res) => {
        if (res != undefined && res.code == 200) {

          this.responseData = res.data[0]
          this.imagePath = `url('${(this.responseData.profilePic == undefined || this.responseData.profilePic == ''? this.IMAGE_BASE_URL+'/default_user.png': this.IMAGE_BASE_URL+'/'+this.responseData.profilePic)}')`
          console.log(this.imagePath)
          this.updateFormGroup.patchValue({...res.data[0]})

        } else {
          if (res) {
            alert(res.message)
          }
        }
      },
      (err) => {

      }
    );

  }

  editProfile(){
    console.log(this.updateFormGroup.value," this is my response data")
  }

  submit() {
    let passValid = true;
    this.submitted = true;
    if (this.updateFormGroup.valid) {
      let api_url = 'Admin/update_agency';
      var formData = new FormData();
      formData.append('email', this.updateFormGroup.value.email)
      formData.append('name', this.updateFormGroup.value.name)
      formData.append('profilePic', this.file != null ? this.file : '')
      formData.append('agency',this.responseData._id);
      formData.append('mobileNumber', this.updateFormGroup.value.mobileNumber)

      if (passValid) {
        this.api.postRequest(api_url, formData).then(
          (res) => {
            this.submitted = false;
            // this.myForm.reset();
            if (res.code == 200) {

              // this.api.setUserLoggedIn(res.result.accessToken, res.result)
              // this.fileUrl = environment.IMG_BASE_URL + '/' + res.result.profilePic;
              this.notifier.notify('success', res.message)
            } else {
              this.notifier.notify('error', res.message)
            }
          },
          (err) => {
          }
        );
      } else {
        console.log("Enter valid details.")
      }

    }
  }

  onFileSelect(event:any){
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    }).then((res:any)=>{
      this.imagePath = `url(${res})`;
      console.log(this.imagePath)
      this.file = event.target.files[0];
      this.detector.detectChanges();
      // let formData = new FormData();
      // formData.append('file',event.target.files[0]);
      // formData.append('user_id',this.id);
      // formData.append('upload_type','1');
      // this.uploadPhoto(formData);
    }).catch((err:any)=>{
      console.log("error is ",err.message)
    })
  }

}