import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiCallingService } from "../../configs/api-calling.service";
import { NotifierService } from "angular-notifier";

@Component({
  selector: 'app-add-subcategory',
  templateUrl: './add-subcategory.component.html',
  styleUrls: ['./add-subcategory.component.css']
})
export class AddSubcategoryComponent implements OnInit {
  public category_name = '';
  public submitted = false;
  public responseData: any = [];
  fileToUpload: File = null;
  public fileUrl: any = '';
  parentId: string = '';
  category_id: string = '';
  subResponseData: any = [];
  model: any = {};
  private readonly notifier: NotifierService;

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private router: Router,
    public api: ApiCallingService,
    public activatedRoute: ActivatedRoute,
    notifierService: NotifierService

  ) {
    this.notifier = notifierService;
  }
  ngOnInit() {
    this.model = {
      category_id: '',
      subcategory_id: ''
    }
    this.activatedRoute.params.subscribe(params => {
      this.model.category_id = params['category_id'];
      this.getData()
    })
  }

  addSubcategory() {
    this.submitted = true;
    if (this.category_name != '') {
      let api_url = 'Admin/add_category'

      var formData = new FormData();

      formData.append('parentId', this.model.category_id)
      formData.append('category_name', this.category_name)
      formData.append('file', this.fileToUpload == null ? '' : this.fileToUpload)
      if (this.fileToUpload && this.model.category_id) {
        this.api.postRequest(api_url, formData).then(
          (res) => {
            this.submitted = false;
            this.fileToUpload = null;
            this.category_name = '';
            this.fileUrl = ''
            if (res && res.code == 200) {
              this.notifier.notify('success', `Success: Subcategory added successfully.`);
              this.router.navigate(['/main/category_management/subcategory', this.model.subcategory_id == '' ? this.model.category_id : this.model.subcategory_id])
            } else {
              this.notifier.notify('error', `Error: ${res.message}`);
            }
          },
          (err) => {

          }
        );
      }

    }
  }
  getData() {

    // stop here if form is invalid 
    let api_url = 'Admin/categories?skip=0&limit=10000'

    this.api.getRequest(api_url, {}).then(
      (res) => {
        if (res != undefined && res.code == 200) {
          this.responseData = res.data

        } else {
          if (res) {
            alert(res.message)
          }
        }
      },
      (err) => {

      }
    );

  }

  getSubcategories(id) {
    let api_url = 'Admin/categoryPopulate?category_id=' + id;

    this.api.getRequest(api_url, {}).then(
      (res) => {
        if (res != undefined && res.code == 200) {
          this.subResponseData = res.data
          this.model.subcategory = ''
        } else {
          if (res) {
            alert(res.message)
          }
        }
      },
      (err) => {

      }
    );
  }

  handleFileInput(files: FileList) {
    var mimeType = files.item(0).type;
    // if (mimeType.match(/image\/*/) == null) {
    // this.file_warning= "Only images are supported.";
    // return;
    // }
    var reader = new FileReader();
    // var img = new Image();
    // this.imagePath = event.target.files;
    reader.readAsDataURL(files.item(0));
    reader.onload = (_event) => {
      this.fileUrl = reader.result;
    }

    this.fileToUpload = files.item(0);
  }
  
  resetForm() {
    this.submitted = false;
    this.fileToUpload == null;
    this.fileUrl = '';
    this.category_name = '';
  }

}
