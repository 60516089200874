import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiCallingService } from "../../../configs/api-calling.service";
import { NotifierService } from "angular-notifier";
import { Subject } from 'rxjs';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-add-admin',
  templateUrl: './add-admin.component.html',
  styleUrls: ['./add-admin.component.css']
})
export class AddAdminComponent implements OnInit {
  public title = '';
  public myForm: FormGroup;
  public submitted: Boolean = false;
  public tabsData: any = [];
  private readonly notifier: NotifierService;
  public checkedArray: any = [];
  public role: string = '';
  public navigate: string = '';
  public agencyList: string = '';
  mobNumberPattern = "^((\\+91-?)|0)?[0-9]{10}$";

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private router: Router,
    public api: ApiCallingService,
    notifierService: NotifierService

  ) { this.notifier = notifierService }

  ngOnInit() {
    this.myForm = this.formBuilder.group({

      name: ['', [Validators.required, this.api.noWhitespace]],
      email: ['', [Validators.required, Validators.compose([Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')])]],
      mobileNumber: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(15), this.api.noWhitespace]],
      authCode: ['', [Validators.required, this.api.noWhitespace]],
      agencyName: ['', [Validators.required, this.api.noWhitespace]],
      avatar: [null]

    });
    if (window.location.hash == '#/main/user_management/add_employee') {
      this.role = 'EMPLOYER'
      this.title = 'Add Employee'
      this.navigate = '/main/user_management';
    } else if (window.location.hash == '#/main/guardian_management/add_guardian') {
      this.role = 'FAMILY'
      this.title = 'Add Guardian'
      this.navigate = '/main/guardian_management';
    } else {
      this.title = 'Add Agency'
      this.role = 'ADMIN';
      this.navigate = '/main/agency_management';
    }


    let api_url = 'Admin/agencyList';

    this.api.getRequest(api_url, {}).then(
      (res) => {
        this.agencyList = res.result.agencyList;
      })
  }

  onFileChange(ev) {
    let dataString: any = [];
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = ev.target.files[0];

    // if (file.size * 0.000001 > 1.5) {

    //   alert("XLXS document must be less than 1.5 MB")

    // } else {

    reader.onload = (event) => {

      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary' });

      jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name]
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        if (initial[name].length > 100000) {
          alert(1)
        } else {
          return initial;
        }

      }, {});

    }

    reader.readAsBinaryString(file);
  }

  submit() {

    this.submitted = true;
    let api_url = 'Admin/add_user'

    var formData = new FormData();
    formData.append('name', this.myForm.value.name)
    formData.append('email', this.myForm.value.email)
    formData.append('mobileNumber', this.myForm.value.mobileNumber)
    formData.append('authCode', this.myForm.value.authCode)
    formData.append('agencyName', this.myForm.value.agencyName)
    formData.append('role', this.role)
    console.log("Ggggg",this.myForm)
    if (this.myForm.valid) {
      this.api.postRequest(api_url, formData).then(
        (res) => {
          if (res) {
            if (res != undefined && res.code == 200) {

              this.notifier.notify('success', `Success: ${this.role == 'EMPLOYER' ? 'Emoplyee' : 'Guardian'} added successfully.`);
              this.resetForm();
              this.router.navigate([this.navigate])
            } else {
              this.notifier.notify('error', `Error: ${res.message}`);
            }
          }
        },
        (err) => {
        }
      );
    }
  }

  generatecode(length) {
    var text = "";
    var possible = "0123456789";

    for (var i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    this.myForm.patchValue({
      authCode: this.role == 'EMPLOYER' ? text : text
    })
    // this.api.postRequest('Admin/generate_code',{}).then(
    //   (res) => {
    //     if (res) {
    //       if (res != undefined && res.code == 200) {

    //         this.notifier.notify('success', `Success: ${this.role == 'EMPLOYER' ? 'Emoplyer' : 'Guardian'} added successfully.`);
    //       } else {
    //       }
    //     }
    //   },
    //   (err) => {
    //   }
    // );
  }

  addPermission(event, id) {
    if (this.checkedArray.includes(id)) {
      this.checkedArray = this.checkedArray.slice(0, this.checkedArray.indexOf(id))

    } else {
      this.checkedArray.push(id)
    }


  }

  resetForm() {
    this.myForm.reset();
    this.checkedArray = [];
  }
}
