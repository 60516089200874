import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router';
import {
  CanActivateRouteGuard as AuthGuard
} from './configs/can-activate-route.guard';
// modules
import { LoginComponent } from './view/login/login.component';
import { ForgotPasswordComponent } from './view/forgot-password/forgot-password.component';
import { DashboardComponent } from './view/dashboard/dashboard.component';
import { MainComponent } from './view/main/main.component';
import { CategoryComponent } from './view/category/category.component';
import { AddCategoryComponent } from './view/add-category/add-category.component';
import { SubcategoryComponent } from './view/subcategory/subcategory.component';
import { AddSubcategoryComponent } from './view/add-subcategory/add-subcategory.component';
import { EditSubcategoryComponent } from './view/edit-subcategory/edit-subcategory.component';
import { UserManagementComponent } from './view/user-management/user-management.component';
import { UserDetailComponent } from './view/user-detail/user-detail.component';
import { EditCategoryComponent } from './view/edit-category/edit-category.component';
import { ChangePasswordComponent } from './view/change-password/change-password.component';
import { ContentManagementComponent } from './view/content-management/content-management.component';
import { PagenotfoundComponent } from './view/pagenotfound/pagenotfound.component';
import { ReportedPostsComponent } from './view/reported-posts/reported-posts.component';
import { AgencyManagementComponent } from './view/agency-management/agency-management.component';
import { AddAdminComponent } from './view/agency-management/add-admin/add-admin.component';
import { ViewAdminComponent } from './view/agency-management/view-admin/view-admin.component';
import { EditAdminComponent } from './view/agency-management/edit-admin/edit-admin.component';
import { GuradianManagementComponent } from './view/guradian-management/guradian-management.component';
import { AddWorkProfileComponent } from './view/user-management/add-work-profile/add-work-profile.component';
import { AddAgencyComponent } from './view/agency-management/add-agency/add-agency.component';
import { RequestManagementComponent } from './view/request-management/request-management.component';
import { RequestViewComponent } from './view/request-management/request-view/request-view.component';
import { ApproveAndNotifyComponent } from './view/request-management/approve-and-notify/approve-and-notify.component';
import { NotificationsComponent } from './view/notifications/notifications.component';
import { SubscriptionComponent } from './view/subscription/subscription.component';
import { UpdateProfileComponent } from './view/update-profile/update-profile.component';
import { CaregiverManagementComponent } from './view/caregiver-management/caregiver-management.component';
const routes: Routes = [
  {
    path: 'login', component: LoginComponent

  },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  {
    path: 'main', component: MainComponent,
    children: [
      { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
      // { path: 'user_management', component: UserManagementComponent, canActivate: [AuthGuard] },
      { path: 'notifications', component: NotificationsComponent, canActivate: [AuthGuard] },
      { path: 'request_management', component: RequestManagementComponent, canActivate: [AuthGuard] },
      { path: 'agency_management', component: AgencyManagementComponent, canActivate: [AuthGuard] },
      { path: 'subscription_management', component: SubscriptionComponent, canActivate: [AuthGuard] },
      // { path: 'guardian_management', component: GuradianManagementComponent, canActivate: [AuthGuard] },

      { path: 'user_management/user_detail/:id', component: ApproveAndNotifyComponent, canActivate: [AuthGuard] },
      { path: 'agency_management/user_detail/:id', component: UserDetailComponent, canActivate: [AuthGuard] },
      { path: 'guardian_management/user_detail/:id', component: UserDetailComponent, canActivate: [AuthGuard] },
      { path: 'request_management/family_detail/:id', component: UserDetailComponent, canActivate: [AuthGuard] },
      { path: 'user_management/add_work_details/:id', component: AddWorkProfileComponent, canActivate: [AuthGuard] },
      { path: 'agency_management/agency_detail/:id', component: UserDetailComponent, canActivate: [AuthGuard] },
      { path: 'guardian_management/guardian_detail/:id', component: UserDetailComponent, canActivate: [AuthGuard] },

      { path: 'request_management/request_detail/:id', component: RequestViewComponent, canActivate: [AuthGuard] },
      { path: 'request_management/approve_and_notify/:id/:request_id/:is_approved', component: ApproveAndNotifyComponent, canActivate: [AuthGuard] },
      { path: 'agency_management/add_agency', component: AddAgencyComponent, canActivate: [AuthGuard] },
      { path: 'user_management/add_employee', component: AddAdminComponent, canActivate: [AuthGuard] },
      { path: 'guardian_management/add_guardian', component: AddAdminComponent, canActivate: [AuthGuard] },

      { path: 'category_management', component: CategoryComponent, canActivate: [AuthGuard] },
      { path: 'category_management/add_category', component: AddCategoryComponent, canActivate: [AuthGuard] },
      { path: 'category_management/edit_category/:id', component: EditCategoryComponent, canActivate: [AuthGuard] },
      { path: 'category_management/edit_Subcategory/:id', component: EditSubcategoryComponent, canActivate: [AuthGuard] },
      { path: 'category_management/subcategory/:id', component: SubcategoryComponent, canActivate: [AuthGuard] },
      { path: 'category_management/add_subcategory/:category_id', component: AddSubcategoryComponent, canActivate: [AuthGuard] },

      { path: 'reported_post', component: ReportedPostsComponent, canActivate: [AuthGuard] },
      { path: 'update_profile', component: UpdateProfileComponent, canActivate: [AuthGuard] },
      { path: 'change_password', component: ChangePasswordComponent, canActivate: [AuthGuard] },
      { path: 'terms_and_condition', component: ContentManagementComponent, canActivate: [AuthGuard] },
      { path: 'privacy_policy', component: ContentManagementComponent, canActivate: [AuthGuard] },
      { path: 'about_us', component: ContentManagementComponent, canActivate: [AuthGuard] },
      { path: 'disclaimer', component: ContentManagementComponent, canActivate: [AuthGuard] },
      { path: 'caregiver_management', component: CaregiverManagementComponent, canActivate: [AuthGuard] },
      { path: '', redirectTo: '/main/dashboard', pathMatch: 'full' },
    ]
  },

  {
    path: '',
    redirectTo: '/main/dashboard',
    pathMatch: 'full'
  },
  { path: '**', component: PagenotfoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false, useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
