import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiCallingService } from "../../configs/api-calling.service";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {
  public responseData: any = [];
  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private router: Router,
    public api: ApiCallingService,
    private activatedRoute: ActivatedRoute
  ) { }
  ngOnInit() {
    this.spinner.show();
    let api_url = 'Admin/get_notifications?skip=' + 0 + '&limit=' + 1000000;

    this.api.getRequest(api_url, {}).then(
      (res) => {
        if (res != undefined && res.code == 200) {
          localStorage.setItem('notificationCount', '');
          this.responseData = res.data

        } else {
          if (res) {
            alert(res.message)
          }
        }
      },
      (err) => {

      }
    );
  }

  AfterViewInit(){
    this.spinner.hide();
  }

}
