import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiCallingService } from "../../configs/api-calling.service";
import Swal from 'sweetalert2';
import { NotifierService } from "angular-notifier";
import * as XLSX from 'xlsx';
declare var $: any;

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent implements OnInit {
  public title = 'Employee Management';
  public responseData: any = [];
  public search = '';
  public submittedXlxs: Boolean = false;
  // pagination var
  itemsPerPage: number = 10;
  totalRecords: number;
  skip: number = 0;
  p: number = 1;
  public agencyList: any = [];
  private readonly notifier: NotifierService;
  public agencyName: string = ''
  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private router: Router,
    public api: ApiCallingService,
    notifierService: NotifierService

  ) {
    this.notifier = notifierService;
  }

  ngOnInit() {
    this.getData();

    let api_url = 'Admin/agencyList';

    this.api.getRequest(api_url, {}).then(
      (res) => {
        this.agencyName = res.result.agencyList[0]._id
        this.agencyList = res.result.agencyList;
      })
  }
  onFileChange(ev) {
    let workBook = null;
    let jsonData: null;
    var finalData = []

    const reader = new FileReader();
    const file = ev.target.files[0];
    reader.onload = async (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary' });
      jsonData = await workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        let res = initial[name]
        for (var i in res) {
          finalData.push({
            Name: res[i].Name,
            Email: res[i].Email,
            MobileNumber: res[i].MobileNumber,
            AuthCode: res[i].AuthCode,
            CountryCode: res[i].CountryCode
          })
        }

        return initial;
      }, {});

      this.submittedXlxs = true;
      let api_url = 'Admin/saveXlxsData'
      this.api.postRequest(api_url, {
        dataToSave: finalData,
        role: 'EMPLOYER',
        agency_name: this.agencyName
      }).then(
        (res) => {
          if (res) {
            if (res != undefined && res.code == 200) {

              this.notifier.notify('success', `Success: ${res.message}`);
              window.location.reload();
              // this.router.navigate(  [this.navigate])
            } else {
              this.notifier.notify('error', `Error: ${res.message}`);
            }
          }
        },
        (err) => {
        }
      );
    }
    reader.readAsBinaryString(file);
  }

  getData() {

    // stop here if form is invalid 
    if (this.search) {
      this.p = 1;
      this.skip = this.itemsPerPage * this.p - this.itemsPerPage;
    }
    let api_url = 'Admin/user_management?skip=' + this.skip + '&limit=' + this.itemsPerPage + '&search=' + this.search + '&type=EMPLOYER';

    this.api.getRequest(api_url, {}).then(
      (res) => {
        if (res != undefined && (res.code == 200 || res.code == 202)) {
          this.totalRecords = res.totalCount;
          this.responseData = res.data
        } else {
          if (res) {
            alert(res.message)

          }
        }
      },
      (err) => {

      }
    );

  }

  activeInactiveUser(val, status) {

    Swal.fire({
      text: status ? 'Are you sure, You want to inactive this employee ?' : 'Are you sure, You want to active this employee?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Submit',
      cancelButtonText: 'Cancel'
    }).then((result) => {

      if (result.value) {

        this.api.postRequest('Admin/blockUnBlockUser', {
          user_id: val,
          block: status ? true : false
        }).then(
          (res) => {

            if (res && res.code == 200) {
              Swal.fire(
                'Success!',
                status ? 'Employee marked inactive successfully.' : 'Employee marked active successfully.',
                'success'
              )

              for (var i in this.responseData) {
                if (this.responseData[i]._id.toString() == val.toString()) {
                  this.responseData[i].isBlocked = status;
                }
              }

            } else {
              Swal.fire(
                'Something Went Wrong.',
                'Please try again after some time.',
                'error'
              )
            }
          },
          (err) => {
            Swal.fire(
              'Something Went Wrong.',
              'Please try again after some time.',
              'error'
            )
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your request has been cancelled',
          'error'
        )
      }
    })
  }

  pageChanged(val) {

    this.skip = this.itemsPerPage * val - this.itemsPerPage;
    this.getData()

  }

  deleteUser(userId) {
    Swal.fire({
      title: 'Are you sure, You want to delete this employee ?',
      text: 'You will not be able to recover this employee.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {

      if (result.value) {

        this.api.postRequest('Admin/deleteUser', { user_id: userId }).then(
          (res) => {
            if (res && res.code == 200) {
              Swal.fire(
                'Deleted!',
                'Employee has been deleted.',
                'success'
              )
              for (var i in this.responseData) {
                if (this.responseData[i]._id.toString() == userId.toString()) {
                  this.responseData.splice(i, 1);
                }
              }

            } else {

              Swal.fire(
                'Something Went Wrong.',
                'Please try Again after some time.',
                'error'
              )
            }

          },
          (err) => {

            Swal.fire(
              'Something Went Wrong.',
              'Please try Again after some time.',
              'error'
            )
          }
        );

      } else if (result.dismiss === Swal.DismissReason.cancel) {

        Swal.fire(
          'Cancelled',
          'Your request has been cancelled',
          'error'
        )
      }
    })
  }

}
