import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiCallingService } from "../../configs/api-calling.service";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-subcategory',
  templateUrl: './subcategory.component.html',
  styleUrls: ['./subcategory.component.css']
})
export class SubcategoryComponent implements OnInit {

  public title = 'Subcategory Management';
  public responseData: any = [];
  public id: string = '';
  public search: string = '';
  // pagination var
  itemsPerPage: number = 10;
  totalRecords: number;
  skip: number = 0;
  p: number = 1;
  hiddenAdd: Boolean= false;
  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private router: Router,
    public api: ApiCallingService,
    private activatedRoute: ActivatedRoute
  ) {
  }
  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.id = params['id'];
      this.getData()
    })
  }

  getData() {

    // stop here if form is invalid 

    let api_url = 'Admin/categoryDetail?skip=' + this.skip + '&limit=' + this.itemsPerPage
    api_url = api_url + '&categoryId=' + this.id;
    if (this.search) {
      api_url = api_url + '&search=' + this.search;
    }
    this.api.getRequest(api_url, {}).then(
      (res) => {
        if (res != undefined && (res.code == 200 || res.code == 202)) {
          this.responseData = res.result.subCategories;
          if (this.responseData.length > 0) {
            if (this.responseData[0].level == 3) {
              this.hiddenAdd = true
            }
          }
        } else {
          if (res) {
            alert(res.message)
          }
        }
      },
      (err) => {

      }
    );

  }

  activeInactiveCategory(val, status) {
    Swal.fire({
      text: !status ? 'Are you sure, You want to inactive this category ?' : 'Are you sure, You want to active this category?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Submit',
      cancelButtonText: 'Cancel'
    }).then((result) => {

      if (result.value) {

        this.api.postRequest('Admin/activeUnactiveCategory', {
          category_id: val,
          active: status ? true : false
        }).then(
          (res) => {

            if (res && res.code == 200) {
              Swal.fire(
                'Success!',
                status ? 'Category marked inactive successfully.' : 'Category marked active successfully.',
                'success'
              )
              for (var i in this.responseData) {
                if (this.responseData[i]._id.toString() == val.toString()) {
                  this.responseData[i].active = status;
                }
              }

            } else {
              Swal.fire(
                'Something went wrong.',
                'Please try again after some time.',
                'error'
              )
            }
          },
          (err) => {
            Swal.fire(
              'Something went wrong.',
              'Please try again after some time.',
              'error'
            )
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your request has been cancelled',
          'error'
        )
      }
    })
  }
}
